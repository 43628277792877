$(document).ready(function () {
  if ($(".js-search-the-collection-input").length) {
    animateCollectionSearchPlaceholders();
  }
});

const animateCollectionSearchPlaceholders = () => {
  const LETTER_DELAY = 150;
  const MESSAGE_DELAY = 2800;

  const INPUT_CLASS = "js-search-the-collection-input";
  const INPUT_SELECTOR = "." + INPUT_CLASS;

  const PLACEHOLDER_CLASS = "js-placeholder";
  const PLACEHOLDER_SELECTOR = "." + PLACEHOLDER_CLASS;

  // show placeholders in label tags one by one set via data-messages
  $(PLACEHOLDER_SELECTOR).each(function () {
    const messages = $(this).data("messages");
    showNextPlaceholder($(this), messages, 0);
  });

  // hide placeholder (label tag) when the user focus on the field to search
  $(INPUT_SELECTOR).focus(function () {
    $(this).parent().next().addClass("hidden");
  });

  function showNextPlaceholder(
    label: any,
    messages: Array<string>,
    index: number
  ) {
    if (index < messages.length) {
      resetPlaceholder(label);
      graduallyShowPlaceholderMessage(label, messages[index], 0, () => {
        setTimeout(function () {
          showNextPlaceholder(label, messages, index + 1);
        }, MESSAGE_DELAY);
      });
    } else {
      // restart from first message
      showNextPlaceholder(label, messages, 0);
    }
  }

  function graduallyShowPlaceholderMessage(
    label: any,
    message: string,
    index: number,
    callback: () => void
  ) {
    if (index < message.length) {
      const currentValue = getPlaceholder(label);

      setPlaceholder(label, currentValue + message[index]);

      setTimeout(function () {
        index = index + 1;
        graduallyShowPlaceholderMessage(label, message, index, callback);
      }, LETTER_DELAY);
    } else {
      callback(); // go to next message after a delay
    }
  }

  function getPlaceholder(label: any) {
    return label.attr("data-placeholder");
  }

  function setPlaceholder(label: any, value: string) {
    label.attr("data-placeholder", value);
  }

  function resetPlaceholder(label: any) {
    setPlaceholder(label, "");
  }
};
